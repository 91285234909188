import React from 'react';
import { graphql } from 'gatsby';

// Components
import News from '../components/pages/news';

export default (props) => <News {...props} />;

export const tagQuery = graphql`
  query TagQuery($tag: String!) {
    allMarkdownRemark(filter: { frontmatter: { tags: { in: [$tag] } } }) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            locale
            title
            tags
            featured {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
          excerpt
        }
      }
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
  }
`;
